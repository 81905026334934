import React, { Component } from 'react';
import Chart from 'chart.js';

class BlockInfo extends Component {
  constructor(props) {
    super(props);
    this.chartRef = React.createRef();
    this.state = {
      timeInterval: 'hours',
      editMode: false,
      editedBlockName: props.block.blockName,
    };
  }

  componentDidMount() {
    this.buildChart();
  }

  componentDidUpdate(prevProps, prevState) {
    if (
      prevProps.block.blockClicks !== this.props.block.blockClicks ||
      prevState.timeInterval !== this.state.timeInterval
    ) {
      this.buildChart();
    }
  }

  buildChart() {
    const { blockClicks } = this.props.block;
    const { timeInterval } = this.state;

    const aggregatedData = this.aggregateDataByTimeInterval(blockClicks, timeInterval);

    const labels = aggregatedData.map((entry) => entry.timeIntervalLabel);
    const data = aggregatedData.map((entry) => entry.clickCount);

    const ctx = this.chartRef.current.getContext('2d');

    if (this.chart) {
      this.chart.destroy();
    }

    this.chart = new Chart(ctx, {
      type: 'bar',
      data: {
        labels,
        datasets: [
          {
            label: 'Clicks',
            data,
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          },
        ],
      },
      options: {
        scales: {
          yAxes: [
            {
              ticks: {
                beginAtZero: true,
              },
            },
          ],
        },
      },
    });
  }

  aggregateDataByTimeInterval(data, interval) {
    const intervalInMillis = this.getTimeIntervalInMillis(interval);
    const aggregatedData = {};
  
    data.forEach((click) => {
      const clickDate = new Date(click);
      const intervalStart = new Date(
        Math.floor(clickDate / intervalInMillis) * intervalInMillis
      );
  
      const timeIntervalLabel = this.formatDate(intervalStart, 'days') + ' ' +
        this.formatDate(intervalStart, 'hours');
  
      if (aggregatedData[timeIntervalLabel]) {
        aggregatedData[timeIntervalLabel] += 1;
      } else {
        aggregatedData[timeIntervalLabel] = 1;
      }
    });
  
    return Object.keys(aggregatedData).map((timeIntervalLabel) => ({
      timeIntervalLabel,
      clickCount: aggregatedData[timeIntervalLabel],
    }));
  }
  

  getTimeIntervalInMillis(interval) {
    const intervals = {
      days: 24 * 60 * 60 * 1000,
      hours: 60 * 60 * 1000,
      weeks: 7 * 24 * 60 * 60 * 1000,
      months: 30 * 24 * 60 * 60 * 1000,
    };

    return intervals[interval] || 1;
  }

  formatDate(timestamp, interval) {
    const date = new Date(timestamp);

    if (interval === 'days') {
      return date.toLocaleDateString();
    } else if (interval === 'hours') {
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else if (interval === 'weeks' || interval === 'months') {
      return date.toLocaleDateString([], { year: 'numeric', month: 'long', day: 'numeric' });
    }

    return '';
  }

  handleBlockNameDoubleClick = () => {
    this.setState({ editMode: true });
  };

  handleBlockNameChange = (e) => {
    this.setState({ editedBlockName: e.target.value });
  };

  saveBlockName = () => {
    const { editedBlockName } = this.state;
    if (editedBlockName.trim() === '') {
      return;
    }
    const { block, updateBlockName } = this.props;
    updateBlockName(block.id, editedBlockName);
    this.setState({ editMode: false });
  };

  handleDeleteClick = () => {
    const shouldDelete = window.confirm('Are you sure you want to delete this block?');
    if (shouldDelete) {
      this.deleteBlock();
    }
  };

  deleteBlock = () => {
    const { block, deleteBlock } = this.props;
    if (deleteBlock) {
      deleteBlock(block.id);
    }
  };

  handleTimeIntervalChange = (newInterval) => {
    this.setState({ timeInterval: newInterval });
  };

  render() {
    const { block, closeBlockInfo } = this.props;
    const { timeInterval, editMode, editedBlockName } = this.state;

    return (
      <div
        className="block-info"
        style={{ background: block.blockColor }}
        onContextMenu={(e) => e.preventDefault()}
      >
        <button className="close-button" onClick={closeBlockInfo}>
          Close
        </button>
        <h2>
          {editMode ? (
            <input
              type="text"
              value={editedBlockName}
              onChange={this.handleBlockNameChange}
              onBlur={this.saveBlockName}
              onKeyUp={(e) => {
                if (e.key === 'Enter') this.saveBlockName();
              }}
            />
          ) : (
            <span onDoubleClick={this.handleBlockNameDoubleClick}>
              {editedBlockName}
            </span>
          )}
        </h2>
        <div className="click-list">
          <canvas ref={this.chartRef} />
          <div className="interval-toggle">
          <label>
            Select Time Interval:
            <select
              value={timeInterval}
              onChange={(e) => this.handleTimeIntervalChange(e.target.value)}
            >
              <option value="days">Days</option>
              <option value="hours">Hours</option>
              <option value="weeks">Weeks</option>
              <option value="months">Months</option>
            </select>
          </label>
        </div>
        </div>
  
        <button className="delete-button" onClick={this.handleDeleteClick}>
          Delete Block
        </button>
      </div>
    );
  }
}

export default BlockInfo;