import React from 'react';
import { Link } from 'react-router-dom';

import { getAuth, onAuthStateChanged} from 'firebase/auth';
import { useEffect, useState,useRef } from 'react'
import {
  collection,
  getDocs,
  query,

} from 'firebase/firestore'
import { useNavigate } from 'react-router-dom'
import FullQuestionComponent from '../components/FullQuestionComponent'


import { db } from '../firebase.config'
function Explore() {
  // Define categories and groups data (you can fetch this data from an API or elsewhere)
  const categories = ['politics', 'israel', 'world', 'funny', 'short term', 'climate', 'sports', 'money'];
  const [userGroups, setUserGroups] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const auth = getAuth();
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(true);
  const isMounted = useRef(true);
  const [isOpened, setIsOpened] = useState(false);
  const [isAuth, setIsAuth] = useState(false);
  const [isLearnMoreVisible, setIsLearnMoreVisible] = useState(true);

  const navigate = useNavigate();
 

  useEffect(() => {
    setLoading(true);
    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setIsAuth(true);
          setFormData({userId: user.uid , userName:user.displayName})
          fetchGroups();
        } else {
          navigate('/sign-in')
        }
      })
    }
    return () => {
      isMounted.current = false
    }
  }, [isMounted])

  const toggleLearnMore = () => {
    setIsLearnMoreVisible(!isLearnMoreVisible);
  };


  useEffect(() => {

    if (formData.userId){

      fetchGroups();
    }
  }, [formData])

  const fetchGroups = async () => {
    try {
   
      const groupsQuery = query(collection(db, 'groups'));

      const groupsSnapshot = await getDocs(groupsQuery);
      const groupsData = groupsSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
    

      const filteredGroups = groupsData.filter((group) => {
        const isMember = group.members && group.members[formData.userId] === true;
        return isMember;
      });
      console.log("fasdf")
      setUserGroups(filteredGroups);
      // setLoading(false);
      // setAddedUsers({});
    } catch (error) {
      console.error('Error fetching groups:', error);
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  return (
    <div className="explore">
      <header>
        <p className="pageHeader">Explore</p>
      </header>

      <main>
        <div className="categories">
          <h2>Who are You</h2>
          <h4>Create your own personal data to find out</h4>
          <button onClick={toggleLearnMore}>
            {isLearnMoreVisible ? 'Hide Text' : 'Learn More'}
          </button>
          {isLearnMoreVisible && (
            <div>
              {/* Additional text to be displayed */}
              <p>
              Enough Data and together we can find patterns and correlations
            that will help you learn more about yourself. 
            You add blocks, choose names like : sleep after 2,  eating proccesed sugar, 
            worked out today, good work day, intersting talk, love life, feeling sad, fight with family,
            being sick, hanging out with friends, driving, not brushing teeth, wasted money, nervous breakdown, 
            when you do one of them, click on its button and the data will be stored.
            Double click on the button to see the data shown in all sorts of presentations.
              </p>
              <p>
              המטרה היא ניטור עצמי כדי לזהות דפוסים וקורלציות בעצמך וללמוד על עצמך. נתחיל בלהוסיף בלוק - הכותרת יכולה להיות כל דבר שניתן לנטר - לדוגמא : לישון אחרי 2, לאכול סוכר מעובד, נהיגה, התאמנתי היום, היה יום עבודה טוב, חולה היום, יום טוב, ישנתי טוב. כשאני הולך לישון אחרי 2, אני לוחץ על הבלוק המתאים והלחיצה נשמרת.  לחיצה כפולה על הבלוק תציג לי גרפים ונתונים  שנוצרים מהדאטא שיצרתי. בהמשך יהיה ניתוחי דאטא יותר מורכבים, שכוללים קשרים בין הבלוקים </p>
            </div>
          )}
       
          {isAuth &&
          <FullQuestionComponent group={{ id: "global" }}  />}
        </div>
      </main>
    </div>
  );
}

export default Explore;
