import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  limit,
  startAfter,
} from 'firebase/firestore'
import { db } from '../firebase.config'
import { toast } from 'react-toastify'
import Spinner from '../components/Spinner'
import ListingItem from '../components/ListingItem'
import FullQuestionComponent from '../components/FullQuestionComponent'


function Category() {

  const params = useParams()

  console.log(params.categoryName);

  return (
    <div className='category'>
      <FullQuestionComponent group={{ id: params.categoryName }}  />
    </div>
  )
}

export default Category
