import FullQuestionComponent from '../components/FullQuestionComponent'
import { useState, useEffect } from 'react'
import { getAuth } from 'firebase/auth'

function Questions() {
  const auth = getAuth();

  // State to manage the active tab

  return (
    <div className='category'>
      <p className='pageHeader'>You</p>
     
      <FullQuestionComponent group={{ id: "global" }}  />
    </div>
  );
}

export default Questions;
