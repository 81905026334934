
import React, { useEffect, useState, useRef } from 'react';
import {
  collection,
  getDocs,
  doc,
  setDoc,
  getDoc,
  updateDoc,
  addDoc,
} from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase.config';
import { toast } from 'react-toastify';
import Spinner from '../components/Spinner';
import { FaPlus } from 'react-icons/fa';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import BlockInfo from './BlockInfo'; // Make sure to provide the correct path


function FullQuestionComponent(group) {
  const [loading, setLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questions, setQuestions] = useState(null);
  const [lastFetchedQuestion, setLastFetchedQuestion] = useState(null);
  const [questionMap, setQuestionMap] = useState({});
  const [predictedNotAnswered, setPredictedNotAnswered] = useState([]);
  const [answered, setAnswered] = useState([]);
  const [openQuestions, setOpenQuestions] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [userPredictionsReference, setUserPredictionsReference] = useState([]);
  const [questionData, setQuestionData] = useState([]);
  const auth = getAuth();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({});
  const isMounted = useRef(true);
  const [activeTab, setActiveTab] = useState('open');
  const [blocks, setBlocks] = useState({});
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [clickStartTime, setClickStartTime] = useState(0);
  const [newBlockName, setNewBlockName] = useState('');
  const [isAddingNewBlock, setIsAddingNewBlock] = useState(false);
  const [selectedBlockInfo, setSelectedBlockInfo] = useState(null);
  const [singleClickTimer, setSingleClickTimer] = useState(null);
  const [lastClickTime, setLastClickTime] = useState(0);



  useEffect(() => {
    setLoading(true);
    if (isMounted) {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          setFormData({ userId: user.uid, me: user.displayName });
        } else {
          navigate('/sign-in');
        }
      });
    }
    return () => {
      isMounted.current = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMounted]);

  useEffect(() => {
    if (formData.userId) {
      fetchBlocks(formData.userId);
    }
  }, [formData]);

  const fetchBlocks = async (userId) => {
    setLoading(true);
    try {
      // Reference the user document directly using the provided userId
      const userRef = doc(db, 'users', userId);
      console.log("TEST!");
      // Get the "blocks" subcollection for that user
      const userDoc = await getDoc(userRef);
      if (userDoc.exists()) {
        console.log("TEST");
        const userData = userDoc.data();
        const bla =userData.blocks || {};
        console.log("DFD",bla)
        setBlocks(userData.blocks || {});
      }

      setLoading(false);
    } catch (error) {
      console.error('Error fetching user blocks:', error);
    }
  };


  const handleBlockClick = (blockId) => {
    if (auth.currentUser.uid) {
      const userBlocksRef = doc(db, 'users', auth.currentUser.uid);
      getDoc(userBlocksRef)
        .then((userDoc) => {
          if (userDoc.exists()) {
            toast.success(blocks[blockId]['blockName']+' Clicked')
            const userData = userDoc.data();
            const existingBlocks = userData.blocks || {};
            // Check if the blockId exists in the user's blocks
            if (existingBlocks[blockId]) {
            
              // Update the blockClicks for the specific block
              const updatedBlockClicks = [
                ...(existingBlocks[blockId].blockClicks || []),
                new Date().getTime(),
              ];
              let tempblocks=blocks;
              tempblocks[blockId]['blockClicks']=updatedBlockClicks;
              setBlocks(tempblocks);
              // setBlocks({
              //   ...existingBlocks,
              //   [blockId]: {
              //     ...existingBlocks[blockId],
              //     blockClicks: updatedBlockClicks,
              //   },
              // })
  
              // Use updateDoc to update only the 'blocks' field
              updateDoc(userBlocksRef, {
                blocks: {
                  ...existingBlocks,
                  [blockId]: {
                    ...existingBlocks[blockId],
                    blockClicks: updatedBlockClicks,
                  },
                },
              })
                .then(() => {
                  // Handle success
                })
                .catch((err) => console.log(err));
            } else {
              console.error('Block does not exist.');
            }
          }
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      console.error('User ID is undefined or empty.');
    }
  };
  
  
  
  const addNewBlock = () => {
    if (auth.currentUser.uid) {
      // Show the input field for adding a new block
      setIsAddingNewBlock(true);
    } else {
      console.error('User ID is undefined or empty.');
    }
  };


  const handleAddNewBlock = () => {
    if (auth.currentUser.uid && newBlockName) {
      toast.success('Block '+newBlockName+' Added')
      // Reset the input field and hide it
      setNewBlockName('');
      setIsAddingNewBlock(false);
  
      const userBlocksRef = doc(db, 'users', auth.currentUser.uid);
  
      // Create a unique ID for the new block
      const newBlockId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  
      // Create the new block data
      const newBlockData = {
        blockName: newBlockName,
        blockColor: getRandomGentleColor(),
        blockClicks: [],
      };
      setBlocks((prevBlocks) => ({
        ...prevBlocks,
        [newBlockId]: newBlockData,
      }));
  
      // Update the user document with the new block data using merge: true
      setDoc(userBlocksRef, { blocks: { [newBlockId]: newBlockData } }, { merge: true })
        .then(() => {
          // Database operation successful
        })
        .catch((err) => {
          // Handle database operation error
          console.error(err);
          // You may want to handle the error, e.g., show a message to the user
        });
    }
  };

  const getRandomGentleColor = () => {
    // Generate a random gentle color
    const letters = '89ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 6)];
    }
    return color;
  };

  const openBlockInfo = (blockId) => {
    const selectedBlock = { ...blocks[blockId], id: blockId };
    setSelectedBlockInfo(selectedBlock);
  };
  const closeBlockInfo = () => {
    setSelectedBlockInfo(null);
  };

  const handleSingleClick = (blockId) => {
    if (singleClickTimer) {
      // A double-click occurred, cancel the single click
      clearTimeout(singleClickTimer);
      setSingleClickTimer(null);
    } else {
      // Set a timer for the single click
      setSingleClickTimer(
        setTimeout(() => {
          // Handle single click here, e.g., call handleBlockClick
          handleBlockClick(blockId);
          setSingleClickTimer(null);
        }, 300) // Adjust the delay as needed
      );
    }
  };

  const handleDoubleclick = (blockId) => {
    clearTimeout(singleClickTimer);
    setSingleClickTimer(null);
    openBlockInfo(blockId);
  };

  const updateBlockName = (blockId, newBlockName) => {
    console.log("LALA",blockId,newBlockName);
    const updatedBlocks = { ...blocks };
    updatedBlocks[blockId].blockName = newBlockName;

    // Update the state
    setBlocks(updatedBlocks);

    // Update the block name in Firebase
    const userBlocksRef = doc(db, 'users', auth.currentUser.uid);
    updateDoc(userBlocksRef, {
      blocks: updatedBlocks,
    })
      .then(() => {
        toast.success('Block name updated');
      })
      .catch((error) => {
        console.error('Error updating block name:', error);
      });
  };

  // Function to delete a block
  const deleteBlock = (blockId) => {

    const updatedBlocks = { ...blocks };
    const deletedBlockName = updatedBlocks[blockId].blockName;
    setSelectedBlockInfo(null);
    // Remove the block from the state
    delete updatedBlocks[blockId];
    setBlocks(updatedBlocks);

    // Delete the block in Firebase
    const userBlocksRef = doc(db, 'users', auth.currentUser.uid);
    updateDoc(userBlocksRef, {
      blocks: updatedBlocks,
    })
      .then(() => {
        toast.success(`Block "${deletedBlockName}" deleted`);
      })
      .catch((error) => {
        console.error('Error deleting block:', error);
      });
  };

  return (
    <div className="category">
      <header>{/* Your header content */}</header>
      {loading ? (
        <Spinner />
      ) : (
        <main>
          <div className="block-grid">
            {Object.keys(blocks).map((blockId) => (
              <div
                key={blockId}
                className="block"
                style={{ '--blockColor': blocks[blockId].blockColor }}
                onClick={() => handleSingleClick(blockId)}
                onDoubleClick={() => handleDoubleclick(blockId)}
              >
                <p>{blocks[blockId].blockName}</p>
              </div>
            ))}
            {isAddingNewBlock ? (
              <div className="newblock" style={{ '--blockColor': 'lightgray' }}>
                <input
                  type="text"
                  placeholder="name"
                  value={newBlockName}
                  onChange={(e) => setNewBlockName(e.target.value)}
                />
                <button onClick={handleAddNewBlock}>Add</button>
              </div>
            ) : (
              <div className="newblock" style={{ '--blockColor': 'lightgray' }} onClick={addNewBlock}>
                <p>Add New Block</p>
              </div>
            )}
          </div>
        </main>
      )}
      {selectedBlockInfo && (
        <div className="modal-overlay">
             <BlockInfo
            block={selectedBlockInfo}
            closeBlockInfo={closeBlockInfo}
            updateBlockName={updateBlockName} // Pass the updateBlockName function
            deleteBlock={deleteBlock} // Pass the deleteBlock function
          />
        </div>
      )}
    </div>
  );
}

export default FullQuestionComponent;