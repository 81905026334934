


import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAspFswYdGudcuXOeq3sikTNkToxlRlu2g",
  authDomain: "emotions599.firebaseapp.com",
  projectId: "emotions599",
  storageBucket: "emotions599.appspot.com",
  messagingSenderId: "1096092748372",
  appId: "1:1096092748372:web:c328b6e9b7767c09793bc1",
  measurementId: "G-3YCYBWE37D"
}


// Initialize Firebase
initializeApp(firebaseConfig)
export const db = getFirestore()

